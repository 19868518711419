.container {
    display: flex;
    flex-direction: column;
    background-color: var(--main-section-background-color);
    color: white;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 20px 50px;
}

.button {
    background-color: var(--continue-button-color);
    color: white;
    font-size: 1.2rem;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    text-decoration: none;
    margin: 2rem;
    cursor: pointer;
}

.error-message {
    font-size: large;
    font-weight: 500;
}
